<template>
  <BaseContainer>
    <BillingSettings />
  </BaseContainer>
</template>

<script>
import BillingSettings from '@components/Settings/BillingSettings'

export default {
  components: {
    BillingSettings
  }
}
</script>
