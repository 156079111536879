<template>
  <div>
    <SettingsNavigation />

    <BaseWrapper>
      <BaseCard
        v-if="paygUpgradeBeforeDate"
        class="mb-4 text-center"
      >
        <p>
          We’ve recently updated our plans. If you require any invoices created before {{ paygUpgradeBeforeDate }}
          get in touch <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>.
        </p>
      </BaseCard>
    </BaseWrapper>

    <div
      class="max-w-screen-xl mx-auto grid md:grid-cols-2 gap-8 px-6"
    >
      <BillingCard class="mb-8" />
      <InvoicesCard class="mb-8" />
    </div>
  </div>
</template>

<script>
import SettingsNavigation from '@components/Settings/SettingsNavigation'
import BillingCard from '@components/Plans/BillingCard'
import InvoicesCard from '@components/Plans/InvoicesCard'

import { mapGetters } from 'vuex'

export default {
  components: {
    SettingsNavigation,
    BillingCard,
    InvoicesCard
  },

  data() {
    return {
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL
    }
  },

  computed: {
    ...mapGetters({
      paygUpgradeBeforeDate: 'organisations/paygUpgradeBeforeDate'
    })
  }
}
</script>
