<template>
  <BaseCard>
    <header class="flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
        >
          <Icon
            width="24px"
            height="24px"
            view-box="0 0 24 24"
          >
            <CreditCard />
          </Icon>
        </span>
        <h2 class="text-lg">
          Invoices
        </h2>
      </div>
    </header>
    <div
      v-if="hasLoaded"
      class="overflow-x-auto mt-4"
    >
      <table
        v-if="invoices.length > 0"
        class="w-full text-sm text-left my-4"
      >
        <thead>
          <tr class="text-gray-500">
            <th class="font-normal">
              Invoice
            </th>
            <th class="font-normal">
              Date
            </th>
            <th class="font-normal">
              Amount
            </th>
            <th class="font-normal">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="invoice in invoices"
            :key="invoice.uuid"
            class="border-b"
          >
            <td class="py-4 pr-2 text-left whitespace-no-wrap">
              <div class="flex items-center">
                <router-link
                  :to="{ name: 'settings-invoice', params: { invoice: invoice.id } }"
                  class="text-secondary hover:text-gray-700 transition duration-150 ease-out"
                  target="_blank"
                >
                  {{ invoice.number }}
                </router-link>
                <!-- <router-link
                  :to="{ name: 'settings-invoice', params: { invoice: invoice.id } }"
                  class="text-secondary hover:text-gray-700 transition duration-150 ease-out"
                  target="_blank"
                >
                  <Icon
                    view-box="0 0 24 24"
                    class="w-5 h-5 ml-2"
                  >
                    <Document />
                  </Icon>
                </router-link> -->
              </div>
            </td>
            <td class="py-4 px-2 text-left whitespace-no-wrap">
              {{ formatDate(parseISO(invoice.createdAt)) }}
            </td>
            <td class="py-4 px-2 text-left whitespace-no-wrap">
              {{ formatCurrency(invoice.amount / 100, invoice.currency) }}
            </td>
            <td class="py-4 pl-2 text-left whitespace-no-wrap">
              {{ invoice.status }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p class="text-gray-500 mt-8 text-sm">
          No payments taken yet
        </p>
      </div>
    </div>
    <div
      v-else
      class="mt-8"
    >
      <Loader />
    </div>
  </Basecard>
</template>

<script>
import CreditCard from '@components/Icons/CreditCard'
// import Document from '@components/Icons/Document'
import Icon from '@components/Icons/Icon'
import Loader from '@components/Loader'

import billingApi from '@api/billing'
import { mapGetters } from 'vuex'
import { parseISO } from 'date-fns'
import { formatCurrency } from '@utils/currency'
import { formatDate } from '@utils/formatDate'

export default {
  components: {
    Icon,
    // Document,
    Loader,
    CreditCard
  },

  data() {
    return {
      formatDate,
      parseISO,
      formatCurrency,
      hasLoaded: false,
      invoices: null
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    })
  },

  created() {
    this.getInvoices()
  },

  methods: {
    /**
     * Fetch the invoices data
     */
    getInvoices() {
      return billingApi.invoices(this.organisationId)
        .then(response => {
          this.hasLoaded = true
          this.invoices = response.data
        })
    }
  }
}
</script>
