<template>
  <BaseCard>
    <header class="flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
        >
          <Icon
            width="24px"
            height="24px"
            view-box="0 0 24 24"
          >
            <CreditCard />
          </Icon>
        </span>
        <h2 class="text-lg">
          Billing
        </h2>
      </div>
    </header>
    <div v-if="hasLoaded">
      <div v-if="billingUpdated">
        <div class="bg-white w-full">
          <div class="p-10 sm:flex-shrink-0 max-w-sm text-center mx-auto">
            <CreditCardVector />
            <h2 class="text-3xl mt-6">
              Thank you
            </h2>
            <p class="text-gray-600 mb-6">
              Your billing information has been updated
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="billing.card">
          <p class="text-gray-500 mt-8 text-sm">
            Current billing information
          </p>
          <dl
            class="flex flex-wrap mt-2 text-sm"
          >
            <dt class="w-1/3 py-4 text-gray-500">
              Card type
            </dt>
            <dd class="w-2/3 py-4">
              {{ billing.card.type }}
            </dd>

            <dt class="w-1/3 py-4 text-gray-500 border-t">
              Last 4 digits
            </dt>
            <dd class="w-2/3 py-4 border-t">
              {{ billing.card.lastFour }}
            </dd>

            <dt class="w-1/3 py-4 text-gray-500 border-t">
              Expiry
            </dt>
            <dd class="w-2/3 py-4 border-t">
              {{ billing.card.month }}/{{ billing.card.year }}
            </dd>
          </dl>
        </div>
        <div v-else>
          <p class="text-gray-500 mt-8 text-sm">
            We currently don’t have any billing information stored
          </p>
        </div>
        <div class="mt-4">
          <ReplaceBillingInformation
            @updateComplete="updateComplete()"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="mt-8"
    >
      <Loader />
    </div>
  </Basecard>
</template>

<script>
import CreditCard from '@components/Icons/CreditCard'
import Icon from '@components/Icons/Icon'
import Loader from '@components/Loader'
import ReplaceBillingInformation from '@components/Plans/ReplaceBillingInformation'
import CreditCardVector from '@components/Vectors/CreditCardVector'

import billingApi from '@api/billing'
import { mapGetters } from 'vuex'

export default {
  components: {
    CreditCardVector,
    Icon,
    Loader,
    ReplaceBillingInformation,
    CreditCard
  },

  data() {
    return {
      billingUpdated: false,
      hasLoaded: false,
      billing: null
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    })
  },

  mounted() {
    this.getBilling()
  },

  methods: {
    /**
     * Fetch the billing data
     */
    getBilling() {
      return billingApi.billing(this.organisationId)
        .then(response => {
          this.hasLoaded = true
          this.billing = response.data
        })
    },

    /**
     * When the billing information has been updated
     */
    updateComplete() {
      this.billingUpdated = true
      this.getBilling()
    }
  }
}
</script>
