<template>
  <div>
    <form
      ref="form"
      class="text-sm mt-8"
      novalidate
      @submit.prevent
    >
      <div>
        <label class="block">
          <span class="text-gray-800">Name on card</span>
          <input
            v-model="cardholderName"
            placeholder="Name on card"
            class="form-input mt-1 text-sm block w-full h-12"
          >
        </label>
      </div>

      <label class="block mt-6">
        <span class="text-gray-800">Card details</span>
        <div
          id="card"
          class="form-input mt-1 text-sm block w-full text-gray-500 px-2 py-4"
        ></div>
        <ErrorsInline v-if="cardError">
          <span>{{ cardError }}</span>
        </ErrorsInline>
      </label>

      <div class="sm:flex sm:justify-between">
        <div class="sm:flex-1 sm:mr-4 mb-6 sm:mb-0">
          <label class="block mt-6">
            <span class="text-gray-800">Country</span>
            <select
              v-model="selectedCountry"
              name="country"
              class="form-select mt-1 block w-full rounded-none text-sm h-12"
            >
              <option
                v-for="(country, index) in countries"
                :key="index"
                :value="country.value"
              >
                {{ country.text }}
              </option>
            </select>
          </label>
          <!-- <ErrorsInline v-if="countryError">
            <span>{{ countryError }}</span>
          </ErrorsInline> -->
        </div>
        <div class="sm:flex-1 mb-6 sm:mb-0">
          <label class="block mt-6">
            <span class="text-gray-800">Postcode</span>
            <input
              v-model="postalCode"
              name="postcode"
              placeholder="Postcode"
              class="form-input mt-1 text-sm block w-full h-12"
            >
          </label>
          <!-- <ErrorsInline v-if="fieldError('postal_code')">
            <span>{{ fieldError('postal_code') }}</span>
          </ErrorsInline> -->
        </div>
      </div>

      <div class="sm:flex sm:justify-between mt-8 relative">
        <p class="text-xs text-gray-500 sm:w-1/2">
          Updating your billing information will take affect from the next payment.
        </p>

        <div class="sm:w-1/2 text-right">
          <BaseButton
            :disabled="!canSubmitCard"
            @click="submitCard()"
          >
            Update billing
          </BaseButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'

import countries from '@groupResources/lists/countries.json'
import subscriptionsApi from '@api/subscriptions'
import { mapGetters } from 'vuex'

// eslint-disable-next-line
const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_TC)
const elements = stripe.elements()
const elementStyle = {
  base: {
    color: '#15151D',
    fontSize: '14px',
    fontFamily: 'Circular, "Helvetica Neue", Helvetica, Arial, sans-serif'
  },
  invalid: {
    color: '#E80404'
  }
}

export default {
  components: {
    ErrorsInline
  },

  data() {
    return {
      card: null,
      cardError: null,
      cardComplete: false,
      cardholderName: '',
      countries,
      postalCode: '',
      selectedCountry: 'GB'
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId',
      userId: 'employers/id'
    }),

    /**
     * Decides if the user can submit the form
     *
     * @return {Boolean}
     */
    canSubmitCard() {
      return this.aCardFieldIsEmpty && this.cardComplete
    },

    /**
     * @return {Boolean}
     */
    aCardFieldIsEmpty() {
      return !!this.cardholderName || !!this.selectedCountry
    },

    /**
     * Returns an object for extra verification from Stripe
     *
     * @return {Object}
     */
    stripeTokenData() {
      return {
        billing_details: {
          name: this.cardholderName,
          address: {
            postal_code: this.postalCode
          }
        }
      }
    }
  },

  watch: {
    selectedCountry(value) {
      this.$emit('selectedCountry', value)
    }
  },

  mounted() {
    this.setUpStripe()
  },

  destroyed() {
    this.card.destroy('card')
  },

  methods: {
    /**
     * Sets the plan data
     */
    submitCard() {
      this.$emit('processing', true)

      console.log('💳 Submit card to Stripe')

      return stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          address: {
            country: this.selectedCountry,
            postal_code: this.postalCode
          },
          name: this.cardholderName
        }
      })
        .then(result => {
          if (result.error) {
            console.error(result.error)
            return
          }
          console.log('💳 Save card response')
          // @TODO 2020-03-25 Replace with update card (create customer if missing)
          return subscriptionsApi
            .createCustomer(this.organisationId, this.userId, result.paymentMethod, this.selectedCountry)
            .then(response => {
              this.$emit('updateComplete')
            })
        })
    },

    /**
     * Sets up Stripe Elements
     */
    setUpStripe() {
      this.card = elements.create('card', {
        style: elementStyle,
        hidePostalCode: true,
        iconStyle: 'solid'
      })
      this.card.mount('#card')

      this.listenForErrors()
    },

    /**
     * Listen for and store errors
     */
    listenForErrors() {
      this.card.addEventListener('change', ({ complete, error }) => {
        this.cardComplete = complete
        if (error) {
          this.cardError = error.message
          return
        }
        this.cardError = null
      })
    }
  }
}
</script>
