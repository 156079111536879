<template>
  <div>
    <div
      v-if="open"
      class="border-t pt-4"
    >
      <div class="flex justify-between">
        <h3 class="font-medium text-lg">
          New Billing Information
        </h3>
        <button
          class="text-gray-500 focus:outline-none"
          @click="open = false"
        >
          <Icon
            width="16px"
            height="16px"
            view-box="0 0 14 14"
            class="transform rotate-45"
          >
            <Plus />
          </Icon>
        </button>
      </div>
      <UpdateBilling
        v-show="!processing"
        @updateComplete="updateComplete()"
        @processing="processing = $event"
      />
      <div
        v-if="processing"
        class="my-8"
      >
        <Loader />
      </div>
    </div>
    <div v-else>
      <BaseButton
        class="w-full"
        @click="open = true"
      >
        Update billing information
      </BaseButton>
    </div>
  </div>
</template>

<script>
import UpdateBilling from '@components/Plans/UpdateBilling'
import Loader from '@components/Loader'
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'

export default {
  components: {
    UpdateBilling,
    Loader,
    Icon,
    Plus
  },

  data() {
    return {
      open: false,
      processing: false
    }
  },

  methods: {
    updateComplete() {
      this.open = false
      this.$emit('updateComplete')
    }
  }
}
</script>
